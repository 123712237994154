import { useState } from "react";
import { Outlet } from "react-router-dom";
import menuItems from "../DataBase/MenuItems";
import { Menu } from "../Menu/Menu";
import './styles.scss';
import { HiTodayIs } from "./HiTodayIs";

const Layout = () => {
    const [menuActive, setMenuActive] = useState(false);

    return (
        <div className="layout-wrapper">
            <header>
                <nav>
                    <div className='burger-btn' onClick={() => setMenuActive(!menuActive)}>
                        <span />
                    </div>
                    <div className='menu-top'>
                        <HiTodayIs />
                    </div>
                </nav>
                <Menu active={menuActive} setActive={setMenuActive} header={'Детально...'} items={menuItems}/>   
            </header>
            <main className="main">
                <Outlet />
            </main>
        </div>
    )
}

export {Layout};