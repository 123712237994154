import { NavLink } from 'react-router-dom';
import './Menu.css';

const Menu = ({header, items, active, setActive}) => {
    return (
        <div className={active ? 'menu active' : 'menu'} onClick={() => setActive(false)}>
            <div className="blur"/>
            <div className="menu__content" onClick={e => e.stopPropagation()}>
                <div className="menu__header">{header}</div>
                <ul>
                    {items.map(item => 
                        <li key={item.id} className="li-item">
                            <NavLink to={item.href}>{item.value}</NavLink>
                            <span className="material-symbols-outlined">{item.icon}</span>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export {Menu};