import React, { useState } from "react";

const Accordion = ({questionTitle, inDetail}) => {
    const [completed, setCompleted] = useState(false);
    
    return (
        <>
            <div className='wrapper'>
                <div className='question-title'>
                    {!completed && <div>{questionTitle}</div>}
                    {completed && <div className="h2act">{questionTitle}</div>}
                    <span onClick={() => setCompleted(!completed)}>
                    {!completed && <img alt="" title='Детально' className='plus'></img>}
                    {completed && <img alt="" title='Приховати деталі' className='minus'></img>}
                    </span> 
                </div>
                {completed &&           
                    <div className="answer">
                        <div className="answers">{inDetail}</div>
                    </div>
                }
            </div>
        </>
    );  
};

export {Accordion};