import { Accordion } from '../../components/Accordion';
import './styles.scss';
import { faqDate } from '../../DataBase/faqDate'

const FaqPage = () => {
  
  return (
    <>
      <div className='box-faq'>
          <div className='main-title-faq'>Найчастіші питання, які ставлять про психоаналіз та терапію</div>
          <div className='faq-img'></div>
      </div>
      {faqDate.map(item => (
        <Accordion 
          key={item.id}
          questionTitle={item.questionTitle}
          inDetail={item.inDetail}
        />
      ))}
    </>
  );
}

export {FaqPage};