import { Routes, Route} from 'react-router-dom';
import './App';
import { MainPage } from './pages/MainPage';
import { AboutMe } from './pages/AboutMe';
import { PostsPage } from './pages/PostsPage';
import { SinglePage } from './pages/Singlepage';
import { ServicesPage } from './pages/ServicesPage';
import { FaqPage } from './pages/FaqPage';
import { ContactPage } from './pages/ContactPage';
import { NotFoundPage } from './pages/NotFoudPage';
import { Layout } from './components/Layout';

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<MainPage />}/>
          <Route path='main' element={<MainPage />}/>
          <Route path='aboutme' element={<AboutMe />}/>
          <Route path='posts' element={<PostsPage />}/>
          <Route path='posts/:id' element={<SinglePage />}/>
          <Route path='services' element={<ServicesPage />}/>
          <Route path='faq' element={<FaqPage />}/>
          <Route path='contacts' element={<ContactPage />}/>
          <Route path='*' element={<NotFoundPage />}/>
        </Route>
      </Routes>
    </div>
  )
}

export {App};