import './styles.scss';

const ServicesPage = () => {
    return (
        <div className='services-page'>
            <div className='services-box'>
                <div className='focus-in-contract-bck'>Послуги психоаналітично-орієнтованого психотерапевта</div>
                <div className='serv-img'></div>
            </div>
            <div className='services-box2'>
                <div className='who-help-me'>Хто може звернутися за допомогою:</div>
                <div className='who-people'>
                    <div className='who-li'>- Дорослі особи</div>
                    <div className='who-li'>- Підлітки з 14 років</div>
                    <div className='who-li'>- Пари</div>
                </div>
                <section className='main-wrapper2'>
                    <div className='info-box-second'>
                        <div>
                            <div className='box-second-title'>Психологічна консультація</div>
                            <div className='box-second-img1'></div>
                            <div className='div-li-second'>
                                <li>Це психологічна допомога людині у пошуку найбільш оптимального вирішення її проблемної ситуації.</li>
                                <li>В процесі психологічної консультації психотерапевт може надати психологічну підтримку, допомогти подивитися на ситуацію під іншими кутом, дати рекомендації щодо навчання нового способу поведінки та способів адаптування, висловити експертну думку, ґрунтуючись на своїх знаннях про психологію людини. Цей процес спрямований переважно на мислення людини.</li>
                                <li>У рамках консультування обговорюються проблеми, що мають конкретний характер, наприклад:</li>
                                <li>- ситуація розлучення;</li>
                                <li>- звільнення з роботи;</li>
                                <li>- проблеми в родині;</li>
                                <li>- будь-який актуальний невроз</li>
                                <li className='li-total'>Для вирішення проблеми може бути достатньо 3-10 зустрічей</li>
                            </div>
                        </div>
                    </div>
                    <div className='info-box-second'>
                        <div>
                            <div className='box-second-title'>Психоаналітична терапія</div>
                            <div className='box-second-img2'></div>
                            <div className='div-li-second'>
                                <li>Психоаналіз та психоаналітична терапія - це процес, спрямований не тільки на вирішення конкретної ситуації, а й на особистісні та життєві зміни.</li>
                                <li>Цей процес торкається глибинних шарів психіки — не стільки думки, скільки почуття та афекти. Відбувається переструктурування внутрішнього світу та переосмислення всього життєвого досвіду. Психоаналіз та психоаналітично-орієнтована терапія призводять до більш глобальних та стійких змін у житті людини.</li>
                                <li>У рамках психоаналітично-орієнтованої терапії вирішуються життєві та особистісні проблеми, що мають тотальний характер:</li>
                                <li>- самотність, складні стосунки, сексуальні проблеми;</li>
                                <li>- незадоволеність собою та життям;</li>
                                <li>- нав'язливі стани та інше...</li>
                                <li className='li-total'>Це триваліший процес — від 10 сесій</li>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className='services-box-bottom'>
                <div className='focus-in-contract-bck'>Зверніться за допомогою до психотерапевта, якщо...</div>
                <div className='box-bootom-dark'>
                    <div className='box-bootom-light'>
                        <ul>
                            <li>... ви вплуталися в якийсь конфлікт, якому не видно кінця</li>
                            <li>... ви весь час повторюєте ту саму помилку або постійно робите неправильний вибір</li>
                            <li>... ваше життя здається вам похмурим, тьмяним і сірим, і що з ним не роби, воно вперто краще не стає</li>
                            <li>... ви перебуваєте в кризі, і весь ваш колишній життєвий досвід виявляється марним і не допомагає вам з нього вибратися</li>
                            <li>... ви ніяк не можете зрозуміти, чого вам від життя хочеться</li>
                            <li>... не можете знайти собі пару для близьких та теплих стосунків</li>
                            <li>... вас мучить тривога</li>
                            <li>... у вас низька самооцінка</li>
                            <li>... ви знаходитесь у творчому глухому куті і не здатні працювати</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {ServicesPage};