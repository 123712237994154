import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { postsDate } from '../../DataBase/postsDate';
import './styles.scss';
import Image from './../../assets/img/post00001.jpg'
import { ButtonToMain } from '../../components/ButtonToMain';

const SinglePage = () => {
    const {id} = useParams();
    const item = postsDate[id - 1];
    const textArr = [];
    
    item.postContent.forEach(el => {
        textArr.push(<div key={Math.round(99997 * Math.random())}>{el}</div>)
    })

    return (  
        <div className='wrap'>
            <div className='box-title-post-page' >
                <div className='img-post-page' >
                    <LazyLoadImage src={Image}
                    width={320} 
                    alt='Image Post'
                    />
                </div>
                <div className='title-data'>
                    <div className='date-post'>{item.publicationDate}</div>
                    <div className='title-post-page'>{item.postTitle}</div>
                </div>               
            </div>
            <div style={{display: 'flex'}}>
                <ButtonToMain className='btn-component'/>
                <Button className='btn-component'/>
            </div>
            <div className='string'>{textArr}</div>
            <div className='post-author'>{item.author}</div>
            <Button />        
        </div>
    )  
}

export {SinglePage};