import './styles.scss';
import VWT from './../../assets/img/pngegg.png';
import MyPic from './../../assets/img/777.jpg';
import FB from './../../assets/img/facebook.png';
import INST from './../../assets/img/instagram.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ContactPage = () => {
    return (
        <section className="contactsPage">
            <div className='info'>
                <div className='phone'>
                    <a href='tel:+380674669120'>+380 67 466 91 20</a>
                </div>
                <div className='vwt'>
                    <LazyLoadImage src={VWT}
                    height={50}
                    alt='Logo Viber, WhatsApp, Telegram'
                    />
                </div>
                <div className='name'>
                    Сергій <br/> Куліда
                    <li>Практикуючий психоаналітично-орієнтований психотерапевт<br></br>Практикуючий КПТ-психотерапевт</li>
                    <li>м. Київ</li>
                </div>
                <div className='mail'>
                    kulida@ua.fm
                </div>
                <ul className='position'>
                    <li>Членство:</li>
                    <li>
                        <a href='https://www.npa-ua.org/reyestr-psihologiv' target='_blank' rel="noopener noreferrer">Націонвльна Психологічна Асоціація</a>
                    </li>
                    <li>
                        <a href='http://pa-uap.org/ua/usi-chlenyi-uap/?page=8&cultureKey=ua' target='_blank' rel="noopener noreferrer">Українська Асоціація Психоаналізу</a>
                    </li>
                    <li>
                        <a href='https://upu.com.ua/uk/registry/member/kulida-sergej-anatolievich/' target='_blank' rel="noopener noreferrer">Український Психоаналітичний Союз</a>
                    </li>
                </ul>
                <div className='links-to-socialnetwork'>
                    <a href='https://www.facebook.com/Psy.Serhii.Kulida' target='_blank' rel="noopener noreferrer" className='social-network'>
                        <LazyLoadImage src={FB}
                        height={50}
                        alt='Facebook'
                        />
                    </a>
                    <a href='https://www.instagram.com/sergeykulida/' target='_blank' rel="noopener noreferrer" className='social-network'>
                        <LazyLoadImage src={INST}
                        height={50}
                        alt='Instagram'
                        />
                    </a>
                </div>
            </div>
            <div className='its-me'>
                <LazyLoadImage src={MyPic}
                    width={375}
                    alt='Куліда Сергій'
                />
            </div>
        </section>
    )
}

export {ContactPage};