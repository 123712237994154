import { useNavigate } from "react-router-dom";
import './styles.scss';

const ButtonToMain = () => {
    const navigate = useNavigate ();
    
    return (
        <button onClick={() => {navigate('/main')}} className='btn-info-detail'>На головну</button>
    )
}

export {ButtonToMain};