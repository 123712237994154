import { NavLink } from 'react-router-dom';
import './styles.scss';

const NotFoundPage = () => {
    return (
        <div className='notFoundPage'>
            <h2>Ми дуже здивовані, яким чином Ви потрапили на цю сторінку?!!!</h2>
            <h5>Такої сторінки не існує. В любому випадку - перейдіть на <NavLink to='/main'>головну.</NavLink ></h5>
        </div>
    )
}

export {NotFoundPage};