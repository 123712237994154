
import './styles.scss';

import { useEffect, useState } from "react";

const HiTodayIs = () => {
    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        const deviceDate = new Date();
        setCurrentDate(deviceDate.toLocaleString('uk-UA', {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        }));
    }, []);

    return (
       <div className='hi-today-is'>
            Сьогодні {currentDate}
        </div>
    )
}

export {HiTodayIs};