import './styles.scss';
import { postsDate } from './../../DataBase/postsDate'
import { Link } from 'react-router-dom';


const PostsPage = () => {

    return (
        <section className="post-page-section">
            <div className='title-postpage'>
                Публікації та дописи
            </div>
            <div className='post-page-box'>
                <div className='item-post-page-small'>
                    {
                        postsDate.map(post => (
                            <Link key={post.id} className='item-public' to={`/posts/${post.id}`}>
                                <div className='item-box'>
                                    <div className='date-post'>{post.publicationDate}
                                        <div>Допис   - {post.id} - </div>
                                    </div>
                                    <div className='item-post-page'>
                                        <div className='post-title'>{post.postTitle}</div>
                                        <div className='post-content'>{post.postContent[0]}</div>
                                    </div>
                                </div>
                                <div className='read-post'>... прочитати допис</div>
                            </Link>
                        ))
                    }
                </div>
            </div>
        </section>
    )  
}

export {PostsPage};