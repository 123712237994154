import './styles.scss';

const MainPage = () => {
    return (
        <div className='main-page'>
            <div className='main-message'>
                <div className='main-sentense'>
                    <h2>ПСИХОАНАЛІТИЧНО-ОРІЄНТОВАНИЙ та КПТ -</h2>
                    <h2>ПСИХОТЕРАПЕВТ</h2>
                    <h2>СЕРГІЙ КУЛІДА</h2>
                    <h4>Знайди радість життя</h4>
                    <h5>ВАШЕ МЕНТАЛЬНЕ ЗДОРОВ'Я В НАДІЙНИХ РУКАХ</h5>
                </div>
                <div className='main-info'>
                    <div className='main-citation'>
                        <h4>«Ви можете займатися трансцендентальною медитацією, можете ходити до церкви і молитися, а можете лягти на 
                            кушетку у психоаналітика і стежити за тим, як із глибин вашого несвідомого спливають бульбашки правди про вас самих»
                        </h4>
                        <h4 className='tom-hanks'>Том Хенкс</h4>
                    </div>
                    <div>
                        <section className='main-wrapper'>
                            <div className='img' title='psy-cabinet'></div>
                            <div className='info-box'>
                                <h3>Чого чекати від візиту до мене</h3>
                                <h5>Згідно з визначенням ВООЗ, «психічне здоров'я є основою добробуту людини та ефективного функціонування спільноти». Різні методи психотерапії та встановлення 
                                    терапевтичного співробітництва допомагають моїм клієнтам виробити звички, які змінюють їхнє життя та дозволяють ефективно справлятися з труднощами. 
                                    Мої сеанси націлені на аналіз та корекцію індивідуальних проблем і дають клієнту можливість справлятись з психічно-навантаженими станами.
                                </h5>
                            </div>
                        </section>
                        <div className='h3-wrapper1'>Допомога психоаналітично-орієнтованого та КПТ психотерапевта</div>
                        <section className='main-wrapper1'>
                            <div className='info-box-third'>
                                <li>- Проблеми, пов'язані зі стосунками в парі</li>
                                <li>- Tруднощі у відносинах - страх "увійти" у відносини, "ніхто не подобається", конфліктність, залежність у відносинах, любовні трикутники, любовна залежність, вибір "не тих"</li>
                                <li>- Cамотність</li>
                                <li>- Проблеми самоідентифікації</li>
                                <li>- Нарцисичний розлад особистості</li>
                            </div>
                            <div className='info-box-third'>
                                <li>- Cексуальні проблеми, питання жіночності/мужності</li>
                                <li>- Тривожні стани, страхи, панічні атаки, фобії, нав'язливості</li>
                                <li>- Невротичні стани різної етиології (розлади харчової поведінки, обсесивно-компульсивна поведінка, проживання втрат, депресивні стани, прокрастинація, перфікціонізм, емоційна залежність)</li>
                                <li>- ПТСР та КПТСР</li>
                            </div>
                            <div className='info-box-third'>
                                <li>- Незадоволеність життям, почуття внутрішньої порожнечі та нереалізованості</li>
                                <li>- Екзистенційні кризи</li>
                                <li>- Потреба у самопізнанні</li>
                                <li>- Психосоматичні захворювання та розлади</li>
                                <li>- Психологічне безпліддя / психологічна імпотенція</li>
                                <li>- Індивідуальна терапія та терапія пар</li>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {MainPage};