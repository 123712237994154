import './styles.scss';

const AboutMe = () => {
    return (
        <div className='about-me'>
            <section className='main-wrapper'>
                <div className='info-boxx'>
                    <div>Про мене</div>
                    <div className='other-info'><span style={{color: 'white'}}>Сергій Куліда</span> — практикуючий психоаналітично-орієнтований та КПТ психотерапевт у м. Київ, який допоможе Вам знайти вихід із дуже складних та заплутаних життєвих ситуацій.</div>
                    <div className='other-info'>Я дбаю про благополуччя своїх клієнтів, надаючи висококваліфіковану психологічну допомогу.</div>
                    <div className='other-info'>З 2012 року надаю спеціалізовані психотерапевтичні послуги людям, які переживають складний період. Хороше самопочуття клієнтів є для мене пріоритетним завданням, і я роблю все можливе, щоб допомогти їм подолати проблеми.</div> 
                    <div className='other-info'>Зв'яжіться зі мною, щоб отримати докладнішу інформацію.</div>
                </div>
                <div className='img' title='psy-cabinet'></div>
            </section>   
            <section className='my-cv'>
                <div className='my-expirience'>Мої професійні навички</div>
                <div style={{fontSize: '28px', color: '#464866'}}>Досвід, що заслуговує на довіру</div>
                <div className='expirience'>
                    <div className='heading'>Базова освіта</div>
                    <div className='string-info'>- НТУУ "КПІ", м. Київ  - ММФ, інженер-технолог, 1997 р.<br></br>- Києво-Могилянська Бізнес Школа, м. Київ  - МВІ для керівників, 2007 р.</div>
                    <div className='heading'>Спеціалізована освіта</div>
                    <div className='string-info'>- <a href='https://migp.pa.org.ua/ua/' target='_blank' rel="noopener noreferrer">Міжнародний Інститут Глибинної Психології</a>, м. Київ  - Практична психологія, психоаналіз, 2015 р.<br></br>
                    - <a href='https://ibo.kneu.edu.ua/ua/' target='_blank' rel="noopener noreferrer">КНЕУ ім. В. Гетьмана / ІБО ім. А. Поручника</a>, м. Київ - Практична психологія, 2024 р.</div>
                    <div className='heading'>Післядипломна освіта</div>
                    <div className='string-info'>- Психоаналіз: Від теорії до практики<br></br>- Психотерапія ПТСР та К-ПТСР<br></br>- КПТ-Психотерапія<br></br>- Психоаналіз у бізнесі<br></br>- Психоаналіз дітей та підлітків<br></br>- Теорія об'єктних відносин<br></br>- Робота з нарцисичними розладами психіки<br></br>- Психоаналіз дипресивних станів<br></br>- Політ-PR</div>
                    <div className='heading'>Участь у професійних співтовариствах</div>
                    <div className='string-info'>
                        - <a href='https://www.npa-ua.org/reyestr-psihologiv' target='_blank' rel="noopener noreferrer">Націонвльна Психологічна Асоціація</a><br></br>
                        - <a href='http://pa-uap.org/ua/usi-chlenyi-uap/?page=8&cultureKey=ua' target='_blank' rel="noopener noreferrer">Українська Асоціація Психоаналіза</a><br></br>
                        - <a href='https://upu.com.ua/uk/registry/member/kulida-sergej-anatolievich/' target='_blank' rel="noopener noreferrer">Український Психоаналітичний Союз</a>
                    </div>
                    <div className='heading'>Викладацька діяльність</div>
                    <div className='string-info'>- Навчальна спеціалізація «Клініка відносин» (Міжнародний Інститут Глибинної Психології), курс «Прикладний психоаналіз в політиці» </div>
                    <div className='heading'>Професійна участь</div>
                    <div className='string-info'>- Індивідуальний та груповий психотерапевт<br></br>- Передвиборні компанії, аналітик-консультант<br></br>- Волонтерський рух - психологічна допомога<br></br>- Бізнес-консультування та кризис-менеджмент<br></br>- Постійний експерт на телебаченні, цикл программ "Будьте здорові"</div>
                    <div className='pic-box'>
                        <div className='about-img1'></div>
                    </div>
                </div>
            </section> 
        </div>
    )
}

export {AboutMe};