import { useNavigate } from "react-router-dom";
import './styles.scss';

const Button = () => {
    const navigate = useNavigate ();
    const goBack = () => navigate(-1);

    return (
        <button onClick={goBack} className='btn-info-detail'>Повернутися</button>
    )
}

export {Button};